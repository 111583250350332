ScrollReveal({ reset: true }).reveal('.card', { scale: 0.5, duration: 800 });
ScrollReveal({ reset: true }).reveal('.heading-sign', {
  scale: 0.5,
  duration: 800,
});
ScrollReveal({ reset: false }).reveal('.about-image', {
  scale: 0.5,
  duration: 800,
});

cssVars({
  // Options...
});
